<template>
  <div class="message-section">
    <v-card v-if="otherUser" class="mr-5">
      <v-card-text class="d-flex flex-row">
        <editable-avatar
          :image="otherUser.thumbnail ? otherUser.thumbnail : otherUser.image"
          :size="50"
          :editable="false"
          class="ml-1"
        />
        <div class="ml-5 mt-1">
          <div
            style="font-size: 20px; font-weight: bold;"
            class="url-button"
            @click="goToProfile"
          >
            {{ otherUser.name }}
          </div>
          <div>{{ otherUser.userType }}</div>
        </div>
      </v-card-text>
    </v-card>
    <div v-if="singleRoom" class="messages-container" ref="messagesContainer">
      <div
        v-for="(message, index) in singleRoom.messages"
        :key="index"
        :class="{
          'message-right': message.fromUser === profile._id,
          'message-left': message.fromUser !== profile._id
        }"
        class="message-wrapper"
        v-observe-visibility="{
          callback: isVisible => onMessageVisible(isVisible, message),
          once: true
        }"
        :ref="index === singleRoom.messages.length - 1 ? 'lastMessage' : null"
      >
        <editable-avatar
          :image="getUserThumbnail(message.fromUser)"
          :size="30"
          :editable="false"
          class="message-avatar"
        />
        <div class="message-content-wrapper">
          <div class="message-content">
            <div class="d-flex justify-space-between align-end">
              <div>{{ message.message }}</div>
              <div>
                <span
                  class="mdi mdi-check check-icon"
                  v-if="!message.read && message.fromUser === profile._id"
                ></span>
                <span
                  class="mdi mdi-check-all actv-check-icon"
                  style="color: #3B7CA1;"
                  v-if="message.read && message.fromUser === profile._id"
                ></span>
              </div>
            </div>
          </div>
          <div class="message-date">
            {{ new Date(message.date).toLocaleString() }}
          </div>
        </div>
      </div>
    </div>
    <div class="message-input-section">
      <v-menu
        bottom
        offset-y
        origin="bottom bottom"
        :close-on-content-click="false"
        v-model="emojiKeyboard"
      >
        <template v-slot:activator="{ on: menu, attrs }">
          <v-btn
            icon
            v-bind="attrs"
            v-on="{ ...menu }"
            v-if="$vuetify.breakpoint.xs"
          >
            <v-icon v-text="'mdi-emoticon-happy-outline'" />
          </v-btn>
          <v-tooltip top v-else>
            <template v-slot:activator="{ on: tooltip }">
              <v-btn icon v-bind="attrs" v-on="{ ...tooltip, ...menu }">
                <v-icon v-text="'mdi-emoticon-happy-outline'" />
              </v-btn>
            </template>
            <span>Open Emoji Keyboard</span>
          </v-tooltip>
        </template>
        <v-card>
          <VEmojiPicker @select="selectEmoji" />
        </v-card>
      </v-menu>
      <v-btn icon @click="triggerFilePicker">
        <v-icon>mdi-paperclip</v-icon>
      </v-btn>
      <input
        type="file"
        ref="uploader"
        style="display: none;"
        @change="onFileSelected"
      />
      <v-textarea
        v-model="newMessage"
        label="Type a message"
        outlined
        dense
        class="message-input"
        hide-details
        rows="1"
        auto-grow
        @keydown.enter.prevent="sendMessage"
        @keydown.shift.enter.native.stop
        ref="message"
      >
        <template v-slot:append>
          <div v-if="selectedFile" style="display: flex; align-items: center;">
            <v-img
              :src="selectedImage"
              alt="image attachment"
              max-width="50px"
              max-height="50px"
              class="ma-2 rounded-lg"
              contain
            />
            <v-btn icon small @click="onRemoveImage" class="ml-2">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
        </template>
      </v-textarea>
      <!-- <v-textarea
        v-model="newMessage"
        label="Type a message"
        outlined
        dense
        class="message-input"
        hide-details
        rows="1"
        auto-grow
        @keydown.enter.prevent="sendMessage"
        @keydown.shift.enter.native.stop
        ref="message"
      />
          <v-btn icon @click="onSelectImage">
              <v-icon v-text="'mdi-image-area'" />
            </v-btn>
         <div
            style="width: 100%; background-color:#f9fafb; border-radius: 0 0 20px 20px"
            v-if="selectedFile"
          >
            <v-divider />
            <div style="position:relative; width: 220px; border-radius: 20px">
              <v-img
                :src="selectedImage"
                alt="image attachment"
                max-width="200px"
                width="200px"
                class="ma-3 rounded-lg"
                contain
              />
              <v-btn
                @click="onRemoveImage"
                class="remove_button"
                fab
                x-small
                color="primary"
              >
                <v-icon v-text="'mdi-close'" size="16" />
              </v-btn>
            </div>
          </div> -->
      <v-btn @click="sendMessage" icon color="primary" class="send-button">
        <v-icon>mdi-send</v-icon>
      </v-btn>
      <!-- <input
        ref="uploader"
        class="d-none"
        type="file"
        accept="image/*"
        @change="onFileChanged"
      /> -->
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import EditableAvatar from "@/components/EditableAvatar.vue";

export default {
  components: { EditableAvatar },
  data() {
    return {
      otherUser: null,
      newMessage: "",
      emojiKeyboard: false,
      selectedFile: null,
      selectedImage: ""
    };
  },
  computed: {
    ...mapGetters({
      profile: "auth/getProfile",
      singleRoom: "chat/getSingleRoom"
    })
  },
  watch: {
    singleRoom: {
      // handler: "updateOtherUser",
      immediate: true,
      handler() {
        this.updateOtherUser();
        this.scrollToLastMessage();
      },
      deep: true
    }
  },
  methods: {
    scrollToLastMessage() {
      this.$nextTick(() => {
        const lastMessage = this.$refs.lastMessage;
        if (lastMessage && lastMessage[0]) {
          lastMessage[0].scrollIntoView({ behavior: "smooth" });
        }
      });
    },
    goToProfile() {
      if (this.otherUser.userType === "Agency") {
        this.$router.push({
          name: "agency-profile-details",
          params: { id: this.otherUser.userID }
        });
      } else if (this.otherUser.userType === "Volunteer") {
        this.$router.push({
          name: "volunteer-profile-details",
          params: { id: this.otherUser.userID }
        });
      }
    },
    selectEmoji(emoji) {
      this.insertText(emoji.data);
      this.makeFocus();
    },
    triggerFilePicker() {
      this.$refs.uploader.click(); // Open the file picker
    },
    onFileSelected(event) {
      const file = event.target.files[0];
      if (file) {
        this.selectedFile = file;

        // Optional: Preview the file if it's an image
        if (file.type.startsWith("image/")) {
          const reader = new FileReader();
          reader.onload = e => {
            this.selectedImage = e.target.result; // Base64 preview
          };
          reader.readAsDataURL(file);
        }
      }
    },
    insertText(text) {
      const el = this.$refs.message.$el.querySelector("textarea"); // Access the textarea element
      if (!el) return; // Ensure the element exists to avoid errors

      let cursorPos = el.selectionEnd || 0; // Get the cursor position
      this.newMessage = // Update the `newMessage` property
        this.newMessage.substring(0, cursorPos) +
        text +
        this.newMessage.substring(cursorPos);
      cursorPos += text.length;

      this.$nextTick(() => {
        el.setSelectionRange(cursorPos, cursorPos); // Set the cursor position after the inserted text
        el.focus(); // Focus the textarea
      });
    },
    makeFocus() {
      this.$refs.message.$refs.input.focus();
    },
    onSelectImage() {
      this.isSelectingPhoto = true;
      window.addEventListener(
        "focus",
        () => {
          this.isSelectingPhoto = false;
        },
        { once: true }
      );
      this.$refs.uploader.value = null;
      this.$refs.uploader.click();
    },
    onRemoveImage() {
      this.selectedFile = null;
      this.selectedImage = "";
    },
    getUserThumbnail(userId) {
      const user = this.singleRoom.users.find(user => user.userID === userId);
      return user ? user.thumbnail : "";
    },
    sendMessage() {
      if (this.newMessage.trim() === "") return;

      const message = {
        fromUser: this.profile._id,
        toUser: this.otherUser.userID,
        message: this.newMessage,
        date: new Date(),
        read: false,
        roomID: this.singleRoom._id,
        fromUserName: this.profile.name
      };

      // Add the message to the singleRoom messages array
      this.singleRoom.messages.push(message);
      // call api addNewMessage
      this.$store.dispatch("chat/addNewMessage", message);
      // Clear the input field
      this.newMessage = "";
      this.scrollToLastMessage();
    },
    updateOtherUser() {
      if (this.singleRoom && this.singleRoom.users && this.profile) {
        this.otherUser = this.singleRoom.users.find(
          user => user.userID !== this.profile._id
        );
      }
    },
    onMessageVisible(isVisible, message) {
      if (isVisible && !message.read && message.toUser === this.profile._id) {
        this.$store.dispatch("chat/markMessageAsRead", {
          roomID: this.singleRoom._id,
          messageID: message._id,
          toUserID: message.fromUser
        });
      }
    }
  },
  // watch: {
  //   singleRoom: {
  //     handler: "updateOtherUser",
  //     immediate: true
  //   }
  // },
  mounted() {
    this.updateOtherUser();
    this.scrollToLastMessage();
  }
};
</script>

<style scoped>
.message-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 70px);
  padding: 0px;
  margin-top: 7px;
}

.messages-container {
  flex-grow: 1;
  margin-right: 20px;
  padding-bottom: 20px;
  overflow-y: auto;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 10px;
  margin-top: 7px;
}

.message-wrapper {
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.message-right {
  flex-direction: row-reverse;
}

.message-left {
  flex-direction: row;
}

.message-avatar {
  margin: 0 10px;
}

.message-content-wrapper {
  max-width: 60%;
}

.message-content {
  font-size: 16px;
  background-color: #e0f7fa;
  padding: 10px;
  border-radius: 10px;
}

.message-date {
  font-size: 12px;
  color: #888;
  text-align: right;
}

.message-input-section {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: #ffffff;
  border-top: 1px solid #ddd;
  margin: 10px;
  border-radius: 46px;
}
.v-textarea.v-text-field--enclosed.v-text-field--outlined.v-input--dense
  textarea {
  margin-top: 6px;
  overflow-y: auto;
  max-height: 200px;
}

.message-input {
  flex-grow: 1;
  margin-right: 10px;
  overflow-y: auto;
  max-height: 167px;
  border-radius: 20px;
  padding: 1px 1px 1px 4px;
}
.send-button {
  min-width: 40px;
}
.check-icon {
  margin: 0px 0px 0px 25%;
}
.actv-check-icon {
  margin: 0px 0px 0px 25%;
}
</style>
